.DefaultHandle_handle {
  width: 24px;
  height: 24px;
  border-width: 10px;
  border-style: solid;
  border-color: #008CFF;
  background-color: #008CFF;
  border-radius: 50%;
  outline: none;
  z-index: 2;
  top: -8px;
}

.DefaultHandle_handle__horizontal {
  margin-left: -18px;
  /* top: -5px */
}

.DefaultHandle_handle__horizontal:before {
  top: 7px;
  height: 10px;
  width: 1px;
  left: 10px
}

.DefaultHandle_handle__horizontal:after {
  top: 7px;
  height: 10px;
  width: 1px;
  left: 13px
}

.DefaultHandle_handle__vertical {
  margin-top: -1px;
  left: -10px
}

.DefaultHandle_handle__vertical:before {
  top: 10px
}

.DefaultHandle_handle__vertical:after {
  top: 10px;
  left: 8px;
  height: 1px;
  width: 10px
}

.DefaultHandle_handle__disabled {
  border-color: #dbdbdb
}

.rheostat {
  position: relative;
  overflow: visible;
  margin-left: 18px;
  margin-right: 11px;
}

@media (min-width: 1128px) {
  .autoAdjustVerticalPosition {
    top: 12px
  }
}

.rheostat__vertical {
  height: 100%
}

.handleContainer {
  height: 15px;
  top: -5px;
  left: -2px;
  bottom: 4px;
  width: 100%;
  position: absolute
}

.rheostat_background__horizontal {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%
}

.rheostat_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%
}

.DefaultBackground {
  background-color: #ffffff;
  height: 15px;
  width: 100%;
  border: 1px solid #B9BFC6;
  position: relative
}

.DefaultHandle_handle {
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 50%;
  outline: none;
  z-index: 2;
  padding: 0px;
}


.DefaultProgressBar__vertical {
  width: 24px;
  height: 1px;
}

.DefaultProgressBar_progressBar {
  background-color: #008CFF;
  position: absolute
}

.DefaultProgressBar_progressBar__vertical {
  height: 2px;
  width: 24px
}

.DefaultProgressBar_background__vertical {
  height: 1px;
  top: 0px;
  width: 15px
}

.DefaultProgressBar_background__horizontal {
  height: 2px;
  top: -2px
}

.DefaultBackground_background__horizontal {
  height: 0px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%
}

.DefaultBackground_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%
}

.rheostat-value,
.rheostat-value1 {
  font-family: ScandiaWeb-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: justify;
  color: #232A2D;
  width: max-content;
  left: 0px;
  display: flex;
  flex-direction: row;
}

.rheostat-value {
  display: none;
}


.priceContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 10px;
  transform: translateY(-10px);
}

.priceContainer p {
  margin: 0px 5px;
}

.range-slider {
  padding-right: 0px;
  padding-left: 0rem;
}

.rheostat-tooltip,
.rheostat-value {
  margin-left: 50%;
  position: absolute;
  text-align: center;

}
.rheostat-marker--large {
  display: none;
}