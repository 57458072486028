@import "./components/RangeSlider/RangeSlider.css";
@import "./components/MenuFilter/MenuFIlter.css";
@import "./components/InfiniteHits/InfiniteHits.css";
@import "./components/types/Types.css";
@import "./components/CurrentRefinement/CurrentRefinements.css";

/* .ant-row {
    display: flex;
    flex-flow: row wrap;
  }
  .ant-row::before,
  .ant-row::after {
    display: flex;
  }
  
  .ant-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
  }
  .ant-col-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  } */
  
  
  
  
.search-app-container {
    padding: 0rem 5rem;
    max-width: 1600px;
    margin: auto;
    margin-bottom: 10rem;
}

.search-homePage {
    display: flex;
}

.top-mobily {
    margin-top: 52px;
}

.ant-card-body {
    padding: 0 !important;
}

.searchBoxClose {
    margin-inline-start: -20px;
    width: 18px;
    height: 18px;
    color: #008CFF;
    z-index: 3;
    position: static;
    cursor: pointer;
}

.searchBoxIcon {
    margin-inline-start: -20px;
    width: 18px;
    height: 18px;
    color: #008CFF;
    z-index: 3;
    position: static;
    display: block;
}

.search-inputField {
    border: none;
    font-family: "ScandiaWeb-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    background-color: transparent;
    font-size: 32px;
    line-height: 40px;
    border-bottom: 2px solid #B9BFC6;
    caret-color: #008CFF;
    position: static;
    z-index: 2;
}

.search-inputField ::placeholder {
    font-family: "ScandiaWeb-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #B9BFC6;
    margin: 0px 0px;
    overflow-x: hidden;
    opacity: 1;
}

.searchBoxOutter {
    margin-bottom: 3rem;

}

.searchBoxRow {
    display: flex;
    align-items: baseline;
    flex-wrap: nowrap;
}

#searchTitle {

    /* Fonts/SW 24 bold */
    font-family: "ScandiaWeb-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: start;
    /* identical to box height, or 133% */


    /* Greys / Grey 500 */

    color: #232A2D;
}

.searchBoxOutter p {
    font-family: ScandiaWeb-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: start;
    /* identical to box height, or 125% */


    /* Greys / Grey 300 */

    color: #868D95;
}

textarea:focus,
input:focus {
    outline: none;
}



/* ###################  */

.searchBox {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: flex-start;
    flex-grow: 5;

}

.searchBoxFeild {
    width: 50% !important;
    max-width: 634px !important;
    min-width: 634px !important;

}

.CustomStats-text {
    margin-top: 5px;

    /* Fonts/SW 16 medium */

    font-family: ScandiaWeb-Medium !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */


    /* Greys / Grey 300 */

    color: #868D95;


    /* Inside auto layout */


    /* margin: 6px 0px; */
}

.right-panel .CustomStats-text {
    margin-inline-start: 5px;
    margin: 5px;
}

/*  to seperate the left panel {filters} from the reasult search {products} */
.right-panel {
    width: 100%;
    margin-inline-start: 2rem;

}

.left-panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* margin-right: 3rem; */

    padding: 0rem 0rem 0rem 0rem;

    flex-grow: 0.1;
    /* height: 100vh; */
    /* border-radius: 1px; */
    border-inline-end: solid 2px;
    border-color: #D5D8DF !important;
    width: 20rem;
    /* background-color: #ced4da; */

}

.big-left-filter-box {
    padding-inline-end: 2.1rem;
}

.filter-box {
    margin-bottom: 50px;
    width: 18rem;
}

.filter-header {

    font-family: ScandiaWeb-Medium;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* margin-left: 5px; */
    /* margin-right: 5px; */
    width: 20rem;
}

/* for the range sliders for both  devices and other types postpaid... */
.all-price {

    padding-bottom: 0%;
    /* border-radius: 5px; */

    background-color: #FFFFFF;
    /* margin: 5px; */
    /* height: 6rem; */
    width: 18rem;
}

.device-price {
    padding-bottom: 50%;
    border-radius: 5px;

    background-color: #FFFFFF;
    margin: 5px;
    height: 9rem;
}

/* .range-slider h3 {
   
    border-bottom: solid 2px #a2a3a5; ;
    margin-bottom: 1.5rem;
} */



.filterButtonSmallScreeen {
    display: none;
}

/* ul {
    margin-block-start: 0em;
    margin-block-end: 0em;
} */

.backButton {
    position: absolute;
    /* right: 0px; */
    top: 2px;
    padding-inline-end: 38px;

}

.backButtonIcon {
    width: 28px;
    height: 28px;

}

@media (max-width: 991px) {
    .search-app-container {
        padding: 0px 16px;
        /* transform: translateX(3%); */
    }

    .search-homePage {
        flex-direction: column;

    }

    .MainPage {
        display: block;
        /* margin-inline-start: 1.8rem; */
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        /* transform: translateX(19px); */
        text-align: center;
    }

    #searchTitle {
        font-size: 18px;
    }

    .searchBoxOutter {
        margin-inline-start: 0rem;
        margin-bottom: 2rem;
    }

    .CustomStats-text {
        font-size: 16px;
    }

    .search-inputField::placeholder {
        font-size: min(max(16px, 4vw), 20px);
    }

    .search-inputField {
        font-size: 18px;
    }

    .right-panel .CustomStats-text {
        margin-inline-start: 45px;
        text-align: start;
        /* display: none; */

    }

    .filterButtonSmallScreeen {
        display: flex;
        background-color: #fff;

        border: none;
        padding: 0px;
        color: #008CFF;
        width: 25% !important;
        max-width: 5.6rem !important;
        min-width: 75px;

        /* align-self: center; */
        margin-bottom: 0.5rem;
        align-items: center;
        /* justify-content: space-around; */
        /* text */


        width: 49px;
        height: 16px;

        /* CTA/12 */

        font-family: 'ScandiaWeb-Medium';

        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        text-align: center;
        letter-spacing: 1.2px;
        text-transform: uppercase;

        color: #008CFF;



    }

    .filterBoxSM {
        height: 100vh;
        background-color: rgb(172, 42, 42);
        top: 10px;
        position: relative;
        width: 100%;
        padding-left: -50px;
        margin-left: -15px;
    }

    .all-price,
    .filter-box {
        width: 100%
    }


    .filterIcons {
        display: flex;
        padding-inline-start: 0.5rem;
    }

    .left-panel-xl {
        display: none;
        margin-right: 0rem;
        border-inline-end: none;
        border-bottom: solid 2px;
        border-color: #D5D8DF !important;
        padding: 0rem;
        width: 98%;
        text-align: start;
        /* align-self: center; */
        /* margin-inline-start: 45px; */
        /* padding-inline-end: 4px; */
    }

    .big-left-filter-box {
        padding-inline-end: 0rem;
    }

    .right-panel {
        margin-inline-start: 0rem;
        /* transform: translateX(18px); */
    }

    .searchBoxFeild {
        width: 100% !important;
        min-width: 240px !important;

    }

    .right-panel .CustomStats-text {

        margin: 5px;
        margin-inline-start: 0px;
    }

    .backButton {
        top: -50px;
        /* right: 0px; */
    }
}

@media (max-width: 500px) {
    .search-inputField {
        border: 1px solid #B9BFC6;
        border-radius: 8px;
        padding-inline-start: 1rem;
        height: 48px;
    }

    input:focus {
        /* outline: none; */
        border: 1px solid #008CFF;

    }

    .search-inputField::placeholder {
        font-size: min(max(16px, 4vw), 14px);
    }
}

@media (max-width: 325px) {
    .search-inputField::placeholder {
        font-size: min(max(16px, 4vw), 13px);
    }

    /* .searchBoxClose {
        width: 12px;
        height: 12px;
    } */

    .filter-box {
        width: 235px;
    }

    .all-price {
        width: 235px;
    }

}