
/* for current refinements filter */
.current-refinements{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* width: 100%; */
    align-items: flex-start;
    padding-inline-start: 0;
    flex-wrap: wrap;
}



.current-refinements a{
    color: #51565B;
    padding: 0.5rem;
    font-weight: bold;
    background: #F2F4F8;
    border-radius: 4px;
    margin-inline-end: 1rem;
    margin-bottom: 1rem;
    width: max-content;
}

.current-refinements a::after {
    /* background-image: url("./assets/times-solid.svg"); */
    content:' ';
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
    font-size: 2px;
    color: #3173aa;
}
.current-refinements li {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
}
.current-refinements li ul {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
    padding: 0px;
}
.close-refinement-icon{
    padding-inline-start: 5px;
    width: 10px;
    height: 14px;
    margin-left: 7px;
    color: #868D95
}
.clear-refinements{
    opacity: 1;
    background-color: #ffffff;
    border: none;
    width:80px !important; 
    min-width: 80px;
    height: 16px;
    left: 934px;
    top: 441px;
    padding-right: 5px;
    padding-left: 5px;

/* CTA/SN 12 bold */

font-family: "SimplonNorm-Medium";
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

text-align: justify;
letter-spacing: 1.2px;
text-transform: uppercase;

/* Mobily Blue */

color: #008CFF;
display: none;

}
.current-refinement-panel {
    display: flex;
    align-content: center;
    flex-direction: row;
    align-items: baseline;
}
.currenet-refinement-price-label{
    text-transform: capitalize;
}
button#clear-refinements:disabled {
    display: none !important;
}


@media (max-width: 991px) {
    .current-refinement-panel{
        /* margin-inline-start: 30px; */
        margin-top: 1rem;
    }
    .current-refinements{
        flex-direction: column;
    }
}
@media (max-width: 500px){
    .current-refinement-panel{
        /* margin-inline-start: 0.5rem; */
        margin-top: 1rem;
    }
    }