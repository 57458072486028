:root {
    --cardMinSizeXL: 320px;
    --cardMinSizeMD: 320px;
    --cardMinSizeSM: 260px;

    --cardMaxWidthXL: 220px;
    --cardMaxHeightXL: 220px;
    --cardMinWidthXL: 220px;
    --cardMinHeightXL: 360px;

    --cardMaxWidthSM: 220px;
    --cardMaxHeightSM: 220px;
    --cardMinWidthSM: 170px;
    --cardMinHeightSM: 292.05px;

    --cardPlanTitleXL: 35px;
    --cardPlanTitleMD: 27px;
    --cardPlanTitleSM: 19px;

    --cardTitlePadding: ;

    --cardDescXL: 28px;
    --cardDescMD: 21px;
    --cardDescSM: 16px;

    --cardPlanBenifitsTitle: 14px;
    --cardPlanDetailsTitle: 22px;

    --cardPriceXL: 20px;
    --cardPriceXL: 20px;
    --cardPriceXL: 20px;
}

.resultsList {
    margin-top: 30px;
    margin-bottom: 30px;
}

.resultTypeTitle {
    font-family: ScandiaWeb-Bold;
    font-size: 32px !important;
    margin: 18px 0;
    text-align: left;
}

.loadMoreBtn {
    margin: 15px;
    text-align: left;
    font-family: ScandiaWeb-Medium;
    font-size: 16px;
    color: #008CFF;
    background-color: transparent;
    border: none;
}

.deviceCard {
    text-align: center;
}

.deviceDescription {
    max-width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.deviceTitle {
    font-family: ScandiaWeb-Medium;
    font-size: 18px;

}

.devicePrice {
    font-family: ScandiaWeb-Regular;
    font-size: 16px;
}

.planInfo {
    position: relative;
    border-radius: 8px;
}

.planDetails {
    position: absolute;
    top: 24px;
    padding-inline-start: 24px;
    color: white;
}

.planTitle {
    font-family: ScandiaWeb-Bold;
    font-size: var(--cardPlanTitleXL);
    line-height: 35px;
    padding-inline-end: 1rem;
}

.planBenifits {
    margin-top: 10px;
}

.planBenifitTitle {
    font-family: ScandiaWeb-Regular;
    font-size: 14px;

}

.planBenifitDetail {
    font-family: ScandiaWeb-Medium;
    font-size: 22px;

}

.planPrice {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    font-family: ScandiaWeb-Medium;
    font-size: 24px;
    line-height: 48px;
    width: 100%;
    color: white;
    padding-inline-start: 19px;
    bottom: 0;
    border-radius: 0px 0px 10px 10px;
}

.priceUnit {
    font-size: 10px;
}

.neqatyCard {
    background: url('https://mobily.com.sa/MobilyTheme4Resources/img/mobily/Fill.png') no-repeat;
    background-color: #003373;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 8px;
    padding-top: 1rem;
    color: #fff;
    text-align: start;
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.neqatyContent {
    z-index: 2;
    width: 95%;
    padding-inline-start: 1rem;
}

.neqatyTitle {
    font-family: ScandiaWeb-Bold;
    font-size: 17px;
    font-weight: 300;
    top: 24px;
    color: white;
    padding-inline-start: 1rem;
    max-height: 83px;
    overflow-y: clip;
    width: 90%;

    line-height: 26px;
}


.neqatyDesc {
    max-height: 173px;
    max-width: 320px;
    padding-inline-end: 0.5rem;
    overflow-y: hidden;
    overflow-x: hidden;
    color: white;
    font-family: ScandiaWeb-Regular;
    font-size: 20px;
    line-height: 30px;
}


.neqatyFooter {
    background: rgba(0, 33, 61, .4);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 2;
}

.neqatyBtn {
    right: 10px;
    font-family: SimplonNorm-Regular;
    width: 95px;
    height: 36px;
    outline: 0;
    border-radius: 20px;
    font-size: 16px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
    text-align: center;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border: 2.5px solid #fff;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    margin: 0.5rem
}

.neqatyFooter .neqatyBtn {
    color: white !important;
}



.planInfo,
.deviceCard,
.neqatyCard {
    aspect-ratio: 109/184;
}

.planTitle {
    font-size: min(max(16px, 4vw), 35px);
}

.neqatyTitle {
    font-size: min(max(16px, 4vw), 20px);

}

.planTitle,
.neqatyTitle {
    padding-inline-end: 10px;
}

.neqatyDesc {
    padding-inline-end: 7px;
}

@media (min-width: 991px) {
    .ant-col {
        max-width: 230px;
        min-width: 220px;
    }
}

@media (max-width: 1100px) {
    .planInfo {
        text-align: start;
    }
}

@media (max-width: 991px) {
    .ant-col {
        min-width: auto;
    }

    .planDetails {

        padding-inline-start: 19px;
    }

}

@media (max-width: 768px) {
    .neqatyDesc {
        font-size: min(max(19px, 6vw), 15px);
        line-height: 21px;
        max-height: 108px;
    }
}

@media (max-width: 576px) {
    .ant-col-xs-24 {
        display: block;
        flex: 0 0 100%;
        max-width: 50%;
    }

    .planInfo,
    .deviceCard,
    .neqatyCard {
        min-width: unset;
    }

    .planTitle,
    .neqatyTitle {
        font-size: min(max(15px, 4vw), 20px);
        line-height: 25px;
        max-height: 72px;
    }

    .planBenifitDetail {
        font-size: min(max(12px, 4vw), 20px);
    }

    .planBenifits {
        margin-top: 5px;
    }

    .neqatyDesc {
        font-size: min(max(14px, 4vw), 15px);
        line-height: 21px;
        max-height: 103px;
        padding-inline-end: 5px;
    }

    .neqatyBtn {
        right: 10px;
        width: 66px;
        height: 25px;
        font-size: 13px;
        line-height: 14px;
        border: 2px solid #fff;
        justify-content: center;
        margin: 0.5rem;
        padding: 0.5rem;
    }
}

@media (max-width: 480px) {

    .planPrice {
        font-size: min(max(18px, 4vw), 20px);
        line-height: min(max(40px, 6vw), 37px);
    }
}

@media (max-width: 350px) {

    .ant-col {
        max-width: 100% !important;
    }

    .planTitle,
    .neqatyTitle {
        font-size: x-large;
    }

    .planPrice {
        font-size: unset;
        line-height: 30px;
    }

    .neqatyDesc {
        max-height: 98px;
    }

    .planPrice {
        padding-inline-start: 10px;
    }

    .planPrice .priceUnit {
        font-size: 10px;
        line-height: 48px;
    }

    .planBenifitDetail {
        font-size: min(max(24px, 4vw), 25px);
    }

    .planInfo,
    .deviceCard,
    .neqatyCard {
        min-width: 265px;
    }

}

@media (min-width: 992px) {
    .left-panel {
        display: flex !important;
    }
}
