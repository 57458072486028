.filter-product {
    list-style: auto;
    padding: 0%;
}

/* for MENU filter */

.menu {
    border-radius: 5px;
    background-color: #FFFFFF;
    height: auto;
}

.menu-icon {
    width: 17px;
    height: 17px;
    color: #008CFF;
}

.menuButton {
    display: flex;
    align-items: flex-start;
}

.menuButton p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: justify;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #008CFF;
    padding-inline-end: 5px;
}

.menuLessButton {
    transform: scaleY(-1);
}

.ais-RefinementList-item {
    margin-bottom: 0.5rem;
}

.ais-RefinementList-labelText {
    padding-left: 5px;
    padding-right: 5px;
    font-family: "ScandiaWeb-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #232A2D;
}

.ais-RefinementList-count {
    background-color: #008CFF;
    border-radius: 20%;

    padding: 0.12rem;
    color: #ffffff;
    display: none;
}

.ais-RefinementList-showMore {
    color: #3616db;
    padding-inline-start: 1.5rem;
}

.ais-RefinementList-showMore[disabled] {
    visibility: hidden;
}

input.ais-RefinementList-checkbox {
    position: relative !important;
    pointer-events: auto !important;
    opacity: 1 !important;
}

.ais-RefinementList-label {
    display: flex;
    align-items: center;
}

.filter-product {
    padding: 0px;
    list-style: none;
}

[type="checkbox"]:not(:checked) {
    width: 18px !important;
    height: 18px !important;
    border: 2px solid #008CFF !important;
    border-radius: 3px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    box-shadow: none;
    font-size: 2em;
}

[type="checkbox"]:checked {
    width: 18px !important;
    height: 18px !important;
    border: 2px solid #008CFF !important;
    border-radius: 3px !important;
    -webkit-appearance: checkbox !important;
    -moz-appearance: checkbox !important;
    -o-appearance: checkbox !important;
    appearance: checkbox !important;
    box-shadow: none;
    font-size: 2em;
}